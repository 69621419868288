import React, { useEffect, useState, useContext } from "react";
import { Badge, Menu, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
// import UserProfile from "./UserProfile";
// import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  // THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { setAllPermissions } from "../../appRedux/actions/Categories";
import { getRoleById } from "../../dispatchAPI";
import { GlobalDataContext } from "../../context/GlobalContext";

import {
  // USER_MANAGEMENT_ACCESS,
  DASHBOARD_ACCESS,
  CATEGORY_ACCESS,
  ROLE_ACCESS,
  USER_ACCESS,
  PRODUCT_ACCESS,
  COUPON_ACCESS,
  OFFICE_SUPPLIES_ACCESS,
  ADDRESS_BOOK_ACCESS,
  VENDOR_ACCESS,
  // ORDER_MANAGEMENT_ACCESS,
  ORDER_ACCESS,
  STORE_LOCATION_ACCESS,
  REVIEW_ACCESS,
  OUR_PARTNER_ACCESS,
  PURCHASE_ORDER_ACCESS,
} from "../../constants/ActionTypes";

import { useSelector, useDispatch } from "react-redux";
import {
  // AppstoreOutlined,
  // ContainerOutlined,
  // DesktopOutlined,
  // MailOutlined,
  UnorderedListOutlined,
  // SettingOutlined,
  // PieChartOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;

// function getItem(label, key, icon, children, type) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//     type,
//   };
// }

// const items = [
//   getItem("Dashboard", "dashboard", <UnorderedListOutlined />),
//   getItem("User Management", "userManagement", <DesktopOutlined />, [
//     getItem("User", "user"),
//     getItem("Roles & Permissions", "roles$Permissions"),
//   ]),
//   getItem("Products Module", "productModules", <DesktopOutlined />),
//   getItem("Orders", "orders", <AppstoreOutlined />, [
//     getItem("New Orders", "newOrders"),
//     getItem("Orders List", "ordersList"),
//   ]),
//   getItem("Invoice Generation", "invoiceGeneration", <ContainerOutlined />),
//   getItem("Shipping", "shipping", <ContainerOutlined />),
//   getItem("Reports", "reportsPage", <ContainerOutlined />),
//   getItem("Settings", "settings", <SettingOutlined />),
// ];

const rootSubmenuKeys = ["userManagement", "orders"];

// const allStaticPermissions = [
//   "user_management_access",
//   "category_create",
//   "category_edit",
//   "category_access",
//   "role_create",
//   "role_edit",
//   "role_access",
//   "user_create",
//   "user_edit",
//   "user_access",
//   "product_create",
//   "product_edit",
//   "product_access",
//   "address_book_create",
//   "address_book_edit",
//   "address_book_access",
//   "order_management_access",
//   "order_create",
//   "order_edit",
//   "order_access",
//   "vendor_create",
//   "vendor_edit",
//   "vendor_access",
//   "store_location_create",
//   "store_location_edit",
//   "store_location_access",
//   "review_access",
//   "review_delete",
//   "our_partner_create",
//   "our_partner_edit",
//   "our_partner_access",
//   "our_partner_delete",
// ];

const SidebarContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [allPermissions, setSelectedPermissions] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [successOrErr, setSuccessOrErr] = useState("success");
  const globalContext = useContext(GlobalDataContext);

  useEffect(() => {
    fetchRolesById(localStorage.getItem("role_id"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage(false);
      }, 500);
    }
  });

  const fetchRolesById = async (id) => {
    const roles = await getRoleById(id);
    let selectedOne = roles.permissions.filter((elem) => elem.is_selected);
    let selectedOneArr = selectedOne.map((elem) => elem.title);
    dispatch(setAllPermissions(selectedOneArr));
    setSelectedPermissions(selectedOneArr);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  let {
    navStyle,
    //themeType,
    pathname,
  } = useSelector(({ settings }) => settings);

  // const allPermissions = useSelector(state => state.categories.allPermissions);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const showAccessError = (columnName) => {
    setTextMessage(`You do not have the access to ${columnName}`);
    setSuccessOrErr("error");
    setShowMessage(true);
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          {/* <UserProfile /> */}
          {/* <AppsNavigation/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          {/* <Menu
            defaultSelectedKeys={[defaultOpenKeys]}
            defaultOpenKeys={[selectedKeys]}
            mode="inline"
            // theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            theme={'lite'}
            style={{backgroundColor: '#E3E2E2'}}
            // inlineCollapsed={collapsed}
            items={items}
            onClick={({item, key, keyPath, domEvent}) => history.push(key)}
          /> */}
          <Menu
            defaultSelectedKeys={[defaultOpenKeys]}
            // defaultOpenKeys={[defaultOpenKeys]}
            defaultOpenKeys={[selectedKeys]}
            selectedKeys={[selectedKeys]}
            onOpenChange={onOpenChange}
            theme={"lite"}
            style={{ backgroundColor: "#E3E2E2", color: "green !important" }}
            // theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
          >
            <Menu.Item
              key="dashboard"
              onClick={() => {
                if (allPermissions.includes(DASHBOARD_ACCESS)) {
                  history.push("/dashboard");
                } else {
                  showAccessError("Dashboard");
                }
              }}
            >
              {/* <Link to="/dashboard"> */}
              <UnorderedListOutlined />
              <span>
                <IntlMessages id="Dashboard" />
              </span>
              {/* </Link> */}
            </Menu.Item>
            <SubMenu
              key="orders"
              icon={
                <img src={require("assets/images/orders/orders.png")} alt="" />
              }
              title="Orders"
            >
              <Menu.Item key="newOrders">
                <Link to="/newOrders">New Orders</Link>
              </Menu.Item>
              <Menu.Item
                key="ordersList"
                onClick={() => {
                  if (allPermissions.includes(ORDER_ACCESS)) {
                    history.push("/ordersList");
                  } else {
                    showAccessError("Orders Lists");
                  }
                }}
              >
                {/* <Link to="/ordersList"> */}
                Orders Lists
                {/* </Link> */}
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="userManagement"
              icon={
                <img
                  src={require("assets/images/sidebar/user-management.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              title="User Management"
            >
              <Menu.Item
                key="user"
                onClick={() => {
                  if (allPermissions.includes(USER_ACCESS)) {
                    history.push("/user");
                  } else {
                    showAccessError("User");
                  }
                }}
              >
                {/* <Link to="/user"> */}
                User
                {/* </Link> */}
              </Menu.Item>
              <Menu.Item
                key="roles$Permissions"
                onClick={() => {
                  if (allPermissions.includes(ROLE_ACCESS)) {
                    history.push("/roles$Permissions");
                  } else {
                    showAccessError("Roles & Permissions");
                  }
                }}
              >
                {/* <Link to="/roles$Permissions"> */}
                Roles & Permissions
                {/* </Link> */}
              </Menu.Item>
              <Menu.Item
                key="addressBook"
                onClick={() => {
                  if (allPermissions.includes(ADDRESS_BOOK_ACCESS)) {
                    history.push("/addressBook");
                  } else {
                    showAccessError("Address Book");
                  }
                }}
              >
                {/* <Link to="/addressBook"> */}
                Address Book
                {/* </Link> */}
              </Menu.Item>
            </SubMenu>

            <Menu.Item
              key="vendors"
              icon={
                <img
                  src={require("assets/images/sidebar/vendors.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(VENDOR_ACCESS)) {
                  history.push("/vendors");
                } else {
                  showAccessError("Vendors");
                }
              }}
            >
              {/* <Link to="/vendors"> */}
              <span>
                <IntlMessages id="Vendors" />
              </span>
              {/* </Link> */}
            </Menu.Item>
            <Menu.Item
              key="partners"
              icon={
                <img
                  src={require("assets/images/sidebar/partners.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(OUR_PARTNER_ACCESS)) {
                  history.push("/partners");
                } else {
                  showAccessError("Partners");
                }
              }}
            >
              {/* <Link to="/partners"> */}
              <span>
                <IntlMessages id="Partners" />
              </span>
              {/* </Link> */}
            </Menu.Item>

            {/* <Menu.Item
              key="employees"
              icon={
                <img
                  src={require("assets/images/productsModule/productsModule.png")}
                />
              }
            >
              <Link to="/employees">
                <span>
                  <IntlMessages id="Employees" />
                </span>
              </Link>
            </Menu.Item> */}

            <Menu.Item
              key="categories"
              icon={
                <img
                  src={require("assets/images/sidebar/categories.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(CATEGORY_ACCESS)) {
                  history.push("/categories");
                } else {
                  showAccessError("Categories");
                }
              }}
            >
              {/* <Link to="/categories"> */}
              <span>
                <IntlMessages id="Categories" />
              </span>
              {/* </Link> */}
            </Menu.Item>
            <Menu.Item
              key="variations"
              icon={
                <img
                  src={require("assets/images/sidebar/variations.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(CATEGORY_ACCESS)) {
                  history.push("/variations");
                } else {
                  showAccessError("Variations");
                }
              }}
            >
              {/* <Link to="/categories"> */}
              <span>
                <IntlMessages id="Variations" />
              </span>
              {/* </Link> */}
            </Menu.Item>
            <Menu.Item
              key="contactus"
              icon={
                <img
                  src={require("assets/images/sidebar/contact-us.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(CATEGORY_ACCESS)) {
                  history.push("/contactus");
                } else {
                  showAccessError("Contact Us");
                }
              }}
            >
              {/* <Link to="/categories"> */}
              <span>
                <IntlMessages id="Contact Us" />{" "}
                <Badge count={globalContext.contactQueriesCount} />
              </span>
              {/* </Link> */}
            </Menu.Item>
            <Menu.Item
              key="productModules"
              icon={
                <img
                  src={require("assets/images/sidebar/products.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(PRODUCT_ACCESS)) {
                  localStorage.setItem("fetchingStatus", true);
                  history.push("/productModules");
                } else {
                  showAccessError("Products");
                }
              }}
            >
              {/* <Link to="/productModules"> */}
              <span>
                <IntlMessages id="sidebar.productsModule" />
              </span>
              {/* </Link> */}
            </Menu.Item>
            <Menu.Item
              key="coupons"
              icon={
                <img
                  src={require("assets/images/sidebar/coupons.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(COUPON_ACCESS)) {
                  history.push("/coupons");
                } else {
                  showAccessError("Coupons");
                }
              }}
            >
              <span>
                <IntlMessages id="Coupons" />
              </span>
            </Menu.Item>
            <Menu.Item
              key="office-products"
              icon={
                <img
                  src={require("assets/images/sidebar/shop-orders.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(OFFICE_SUPPLIES_ACCESS)) {
                  history.push("/shop-orders");
                } else {
                  showAccessError("Shop Orders");
                }
              }}
            >
              <span>
                <IntlMessages id="Shop Orders" />
              </span>
            </Menu.Item>

            <Menu.Item
              key="reviews"
              icon={
                <img
                  src={require("assets/images/sidebar/reviews.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(REVIEW_ACCESS)) {
                  history.push("/reviews");
                } else {
                  showAccessError("Reviews");
                }
              }}
            >
              {/* <Link to="/reviews"> */}
              <span>
                <IntlMessages id="Reviews" />
              </span>
              {/* </Link> */}
            </Menu.Item>

            <Menu.Item
              key="purchaseOrder"
              icon={
                <img
                  src={require("assets/images/sidebar/purchase-order.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(PURCHASE_ORDER_ACCESS)) {
                  history.push("/purchaseOrder");
                } else {
                  showAccessError("Purchase Order");
                }
              }}
            >
              {/* <Link to="/reviews"> */}
              <span>
                <IntlMessages id="Purchase Order" />
              </span>
              {/* </Link> */}
            </Menu.Item>

            <Menu.Item
              key="stateSalesTax"
              icon={
                <img
                  src={require("assets/images/sidebar/state-sale-tax.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              onClick={() => {
                if (allPermissions.includes(PURCHASE_ORDER_ACCESS)) {
                  history.push("/stateSalesTax");
                } else {
                  showAccessError("State Sales Tax");
                }
              }}
            >
              {/* <Link to="/reviews"> */}
              <span>
                <IntlMessages id="State Sales Tax" />
              </span>
              {/* </Link> */}
            </Menu.Item>

            <SubMenu
              key="locations"
              icon={
                <img
                  src={require("assets/images/orders/orders.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              title="Locations"
            >
              <Menu.Item
                key="storeLocation"
                onClick={() => {
                  if (allPermissions.includes(STORE_LOCATION_ACCESS)) {
                    history.push("/storeLocation");
                  } else {
                    showAccessError("Store Location");
                  }
                }}
              >
                {/* <Link to="/storeLocation"> */}
                Store Location
                {/* </Link> */}
              </Menu.Item>

              <Menu.Item
                key="stockLocation"
                onClick={() => {
                  if (allPermissions.includes(ADDRESS_BOOK_ACCESS)) {
                    history.push("/stockLocation");
                  } else {
                    showAccessError("Stock Locations");
                  }
                }}
              >
                {/* <Link to="/addressBook"> */}
                Stock Locations
                {/* </Link> */}
              </Menu.Item>
            </SubMenu>

            {/* <Menu.Item
              key="invoiceGeneration"
              icon={
                <img
                  src={require("assets/images/invoiceGeneration/invoiceGeneration.png")}
                />
              }
            >
              <Link to="/invoiceGeneration">
                <span>
                  <IntlMessages id="sidebar.invoiceGeneration" />
                </span>
              </Link>
            </Menu.Item> */}

            {/* <Menu.Item
              key="shipping"
              icon={
                <img src={require("assets/images/shipping/shipping.png")} />
              }
            >
              <Link to="/shipping">
                <span>
                  <IntlMessages id="sidebar.shipping" />
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="reportsPage"
              icon={<img src={require("assets/images/reports/reports.png")} />}
            >
              <Link to="/reportsPage">
                <span>
                  <IntlMessages id="sidebar.reports" />
                </span>
              </Link>
            </Menu.Item> */}
            <SubMenu
              key="reports"
              icon={
                <img
                  src={require("assets/images/productsModule/productsModule.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
              title="Reports"
            >
              {/* <Menu.Item
                key="allReports"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>All Reports</span>
              </Menu.Item> */}
              <Menu.Item
                key="topSellingItemsReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/top-selling-items");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Top Selling Items</span>
              </Menu.Item>
              <Menu.Item
                key="topSalesComparisonReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/sales-comparison");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Sales Comparison</span>
              </Menu.Item>
              <Menu.Item
                key="salesTaxReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/sales-tax");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Sales Tax</span>
              </Menu.Item>
              <Menu.Item
                key="jobsCompletedReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/jobs-completed");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Jobs Completed</span>
              </Menu.Item>
              <Menu.Item
                key="salesTaxExemptionReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/sales-tax-exemption");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Sales Tax Exemption</span>
              </Menu.Item>
              <Menu.Item
                key="salesTaxSummarizationReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/sales-tax-summarization");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Sales Tax Summarization</span>
              </Menu.Item>
              <Menu.Item
                key="outstandingOrdersReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/outstanding-orders");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Outstanding Orders</span>
              </Menu.Item>
              <Menu.Item
                key="paymentTransactionsReport"
                onClick={() => {
                  if (allPermissions.includes(REVIEW_ACCESS)) {
                    history.push("/reports/payment-transactions");
                  } else {
                    showAccessError("Reports");
                  }
                }}
              >
                <span>Payment Transactions</span>
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              key="settings"
              icon={
                <img
                  src={require("assets/images/sidebar/settings.png")}
                  alt=""
                  width={18}
                  height={18}
                />
              }
            >
              <Link to="/settings">
                <span>
                  <IntlMessages id="sidebar.settings" />
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
        {showMessage ? message[successOrErr](textMessage) : null}
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
